import React from "react"
import {graphql} from "gatsby";
import Img from "gatsby-image"
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet'
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Map} from "leaflet/dist/leaflet-src.esm";
import {Card, Col, Row} from "react-bootstrap";
import teamImg from "../images/team.jpg"

import elkeImg from "../images/team/ÄrztinDrElkeHeinitz.jpg";
import ankeImg from "../images/team/ÄrztinDrAnkeHumke.jpg";
import michaelImg from "../images/team/ArztDrMichaelTeilken.jpg";

import lianeImg from "../images/team/MedizinischeFachangestellteLianeSchöning.jpg";
import siljaImg from "../images/team/MedizinischeFachangestellteSiljaLange.jpg";
import jessicaImg from "../images/team/MedizinischeFachangestellteJessicaLühr.jpg";
import benjaminImg from "../images/team/BenjaminSchmidt.jpg";


export const query = graphql`
query {
    allFile(filter: {relativePath: {glob: "team/*"}}) {
        edges {
            node {
                name
                childImageSharp {
                    fluid(fit: COVER, maxHeight: 300){
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
}


`
export default class TeamPage extends React.Component {
    constructor(props) {
        super(props)
        this.images = {};
        props.data.allFile.edges.map((node) => {
            this.images[node.node.name] =  node.node.childImageSharp.fluid
        });

    }


    render() {

        const team = [
            {
                "name": "Dr. Elke Natasha Heinitz",
                "image": this.images["ÄrztinDrElkeHeinitz"],
            },
            {
                "name": "Dr. Anke Humke",
                "image": this.images["ÄrztinDrAnkeHumke"],
            },
            {
                "name": "Dr. Michael Teilken",
                "image": this.images["ArztDrMichaelTeilken"],
            },
            {
                "name": "Silja Lange <br/> MFA",
                "image": this.images["MedizinischeFachangestellteSiljaLange"],
            },
            {
                "name": "Liane Schöning <br/> MFA",
                "image": this.images["MedizinischeFachangestellteLianeSchöning"],
            },
            {
                "name": "Jessica Lühr <br/> MFA ",
                "image": this.images["MedizinischeFachangestellteJessicaLühr"],
            },
            {
                "name": "Benjamin Schmidt <br/> Kinder- und Jugendlichen-Psychotherapeut in Ausbildung",
                "image": this.images["BenjaminSchmidt"],
            },
        ]
        console.log(team)
        return (
            <Layout bgImage={teamImg}>
                <SEO title="Team"/>

                <section className="page-section" id="services">
                    <div className="container">
                        <h1 className="text-center mt-0">Team</h1>
                        <hr className="divider my-4"/>
                        <div className="row text-center justify-content-center">
                            <Row xs={1} md={3} className={"g-4 justify-content-center"}>
                                {team.map((person, idx) => (
                                    <Col>
                                        <Card>
                                            <Card.Img variant="top" src={person.image.src}/>
                                            <Card.Body>
                                                <Card.Title><div dangerouslySetInnerHTML={{__html:person.name}} ></div></Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                </section>


            </Layout>
        )
    }
}

